import { css } from '@emotion/react'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const container = css`
    overflow: hidden;
`;

export const header = css`
    margin-top: 49px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 56px;
	}
`;

export const timesTop = css`
    margin-top: 38px;
	display: grid;
    gap: 25px;
	grid-template-columns: minmax(0, 1fr);
	@media (min-width: ${breakpoints.pc_header}px) {
        margin-top: 57px;
		grid-template-columns: minmax(0, 1fr) 318px;
		gap: 0 43px;
	}
`;

export const slider = css`
    margin-left: -20px;
    margin-right: -20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: -40px;
        margin-right: -40px;
	}
	@media (min-width: ${breakpoints.pc_header}px) {
		margin: 0;
	}
`;

export const keywordListTitle = css`
    font-size: 2.4rem;
	line-height: 40px;
    font-weight: bold;
    color: ${palette.text.primary};
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
	}
`;

export const keywordList = css`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
	gap: 8px 10px;
    li:nth-of-type(n+7) {
        display: none;
    }
	@media (min-width: ${breakpoints.tablet}px) {
		li:nth-of-type(n+7) {
			display: block;
		}
	}
`;

export const tag = css`
    :hover {
        opacity: 0.7;
    }
`;

export const sectionContainer = css`
	margin-top: 24px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 60px;
	}
`

export const sectionHeader = css`
	position: relative;
    
	@media (min-width: ${breakpoints.tablet}px) {
		align-items: flex-end;
	}
`

export const sectionTexts = css`
    flex: 1;
`;

export const sectionTextsFormer = css`
	display: flex;
    justify-content: space-between;
	align-items: center;
`;

export const sectionTitle = css`
	color: ${palette.text.primary};
	font-size: 2.4rem;
	line-height: 40px;
	font-weight: bold;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 3.2rem;
	}
`

export const sectionSubtitle = css`
	font-size: 1.3rem;
	font-weight: 500;
	margin-top: 4px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 9px;
		font-size: 1.6rem;
	}
`

export const sectionLintText = css`
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 128%;
	color: ${palette.primary.main};

	:hover {
		color: ${palette.primary.light};
	}
	@media (min-width: ${breakpoints.tablet}px) {
	 	font-size: 1.6rem;
    }
`

export const newsList = css`
	margin-top: 10px;
	display: flex;
	overflow-x: scroll;
	gap: 13px;

	:after {
		min-width: 20px;
		height: 1px;
		content: '';
	}

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 30px;
		gap: 42px;
		:after {
			display: none;
		}
	}
`

export const newsItem = css`
	width: calc(100% - 45px);
	min-width: calc(100% - 45px);

	@media (min-width: ${breakpoints.tablet}px) {
		min-width: auto;
		width: 346px;
	}
`

export const linkButton = css`
	margin-top: 30px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin: 80px auto;
		width: 376px;
	}
`
