import * as React from 'react'
import { useOnsenTimesContents } from '~/hooks/useOnsenTimesContents'
import * as styles from './styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { palette } from '~/utils/colors'
import { Link } from 'gatsby'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true
}

export const TopSlider = () => {
    const contents = useOnsenTimesContents(7)

    return (
        <Slider {...settings} css={styles.slider}>
            {contents.map(({ node }) => (
                <li key={node.newsId}>
                    <Link to={`/times/${node.newsId}`}>
                        <article css={[{
                            background: `${palette.shadow.black}, url(${node.thumbnail.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }, styles.article]}
                        >
                            <div css={styles.texts}>
                                <span css={styles.category}>{node.category[0]}</span>
                                <p css={styles.title}>{node.title}</p>
                                <span css={styles.date}>{node.postDate}</span>
                            </div>
                        </article>
                    </Link>
                </li>
            ))}
        </Slider>
    )
}