import React from 'react'
import { PageProps } from 'gatsby'
import SEO from '~/utils/seo'
import { Times } from "~/components/pages/Times"

export default function OnsenTimes({ location }: PageProps) {
    return (
        <>
            <SEO
                title='お風呂の情報発信メディア - Onsen* Times | Onsen*'
                description='Onsen*のサービスや製品に関する最新情報やメディア掲載情報、イベント情報やお風呂にまつわる記事など多種多様なニュースを掲載しています。'
                url={location.href}
            />
            <Times/>
        </>
    )
}