import * as React from 'react'
import { Link } from 'gatsby'
import { Container } from '~/components/layout/Container'
import { useOnsenTimesContentsEvent } from '~/hooks/useOnsenTimesContentsEvent'
import { useOnsenTimesContentsJournal } from '~/hooks/useOnsenTimesContentsJournal'
import { useOnsenTimesContentsMedia } from '~/hooks/useOnsenTimesContentsMedia'
import { useOnsenTimesContentsStore } from '~/hooks/useOnsenTimesContentsStore'
import NewsCard from '~/components/molecules/NewsCard'
import { summarize } from '~/utils/seo'
import Button from '~/components/atoms/Button'
import * as styles from './styles'
import { useOnsenTimesKeywords } from '~/hooks/useOnsenTimesKeywords'
import Tag from '~/components/atoms/Tag'
import { TopSlider } from '~/components/domain/times/TopSlider'
import { Header } from '~/components/domain/times/Header'

export const Times = () => {
    const events = useOnsenTimesContentsEvent(3)
    const journals = useOnsenTimesContentsJournal(3)
    const medias = useOnsenTimesContentsMedia(3)
    const stores = useOnsenTimesContentsStore(3)
    const keywords = useOnsenTimesKeywords(20)

    return (
        <Container css={styles.container}>
            <section css={styles.header}>
                <Header/>
            </section>
            <section css={styles.timesTop}>
                <div css={styles.slider}>
                    <TopSlider/>
                </div>
                <div>
                    <h2 css={styles.keywordListTitle}>人気のキーワード</h2>
                    <ul css={styles.keywordList}>
                        {keywords.map(({node}, index) => (
                            <li key={`${node.keywordsId}-${index}`}>
                                <Link to={`/times/${node.keywordsId}`}>
                                    <Tag css={styles.tag}>{node.title}</Tag>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            <section css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>
                    <div css={styles.sectionTexts}>
                        <div css={styles.sectionTextsFormer}>
                            <h2 css={styles.sectionTitle}>ストア・製品</h2>
                            <Link to='/times/store/'>
                                <span css={styles.sectionLintText}>すべて見る</span>
                            </Link>
                        </div>
                        <p css={styles.sectionSubtitle}>別府由来の薬用入浴剤や、専用アプリに関する最新情報です。</p>
                    </div>
                </div>

                <ul css={styles.newsList}>
                    {stores.map(({ node }) => (
                        <NewsCard
                            key={node.newsId}
                            css={styles.newsItem}
                            title={node.title}
                            description={summarize(node.contents, 60)}
                            thumbnail={node.thumbnail.url}
                            postDate={node.postDate}
                            publishedAt={node.publishedAt}
                            newsId={node.newsId}
                        />
                    ))}
                </ul>
            </section>

            <section css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>
                    <div css={styles.sectionTexts}>
                        <div css={styles.sectionTextsFormer}>
                            <h2 css={styles.sectionTitle}>メディア掲載</h2>
                            <Link to='/times/media/' css={styles.sectionLintText}>
                                <span>すべて見る</span>
                            </Link>
                        </div>
                        <p css={styles.sectionSubtitle}>Onsen*を紹介いただいたメディア情報をお知らせします。</p>
                    </div>
                </div>

                <ul css={styles.newsList}>
                    {medias.map(({ node }) => (
                        <NewsCard
                            key={node.newsId}
                            css={styles.newsItem}
                            title={node.title}
                            description={summarize(node.contents, 60)}
                            thumbnail={node.thumbnail.url}
                            postDate={node.postDate}
                            publishedAt={node.publishedAt}
                            newsId={node.newsId}
                        />
                    ))}
                </ul>
            </section>

            <section css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>
                    <div css={styles.sectionTexts}>
                        <div css={styles.sectionTextsFormer}>
                            <h2 css={styles.sectionTitle}>お風呂ジャーナル</h2>
                            <Link to='/times/journal/'>
                                <span css={styles.sectionLintText}>すべて見る</span>
                            </Link>
                        </div>
                        <p css={styles.sectionSubtitle}>おふろや睡眠などあなたを整えるための情報をお届けします。</p>
                    </div>
                </div>

                <ul css={styles.newsList}>
                    {journals.map(({ node }) => (
                        <NewsCard
                            key={node.newsId}
                            css={styles.newsItem}
                            title={node.title}
                            description={summarize(node.contents, 60)}
                            thumbnail={node.thumbnail.url}
                            postDate={node.postDate}
                            publishedAt={node.publishedAt}
                            newsId={node.newsId}
                        />
                    ))}
                </ul>
            </section>

            <section css={styles.sectionContainer}>
                <div css={styles.sectionHeader}>
                    <div css={styles.sectionTexts}>
                        <div css={styles.sectionTextsFormer}>
                            <h2 css={styles.sectionTitle}>イベント情報</h2>
                            <Link to='/times/event/'>
                                <span css={styles.sectionLintText}>すべて見る</span>
                            </Link>
                        </div>
                        <p css={styles.sectionSubtitle}>商品がお試しいただけるイベント情報をお知らせします。</p>
                    </div>
                </div>

                <ul css={styles.newsList}>
                    {events.map(({ node }) => (
                        <NewsCard
                            key={node.newsId}
                            css={styles.newsItem}
                            title={node.title}
                            description={summarize(node.contents, 60)}
                            thumbnail={node.thumbnail.url}
                            postDate={node.postDate}
                            publishedAt={node.publishedAt}
                            newsId={node.newsId}
                        />
                    ))}
                </ul>
            </section>

            <Link to='/times/page/'>
                <Button css={styles.linkButton}>
                    全ての記事を表示する
                </Button>
            </Link>
        </Container>
    )
}