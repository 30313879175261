import { css } from "@emotion/react"
import { palette } from '~/utils/colors'

export const slider = css`
	.slick-dots {
		bottom: 14px;
        font-size: 1.2rem;
        
        li {
            margin: 0;
        }
        
        li button:before {
            color: ${palette.grey['300']};
            opacity: 1;
            :hover {
                opacity: 0.2;
            }
        }
        li.slick-active button:before {
            color: ${palette.common.white};
            opacity: 1;
        }
    }
`

export const article = css`
    position: relative;
    aspect-ratio: 16/9;
    :hover {
        :before {
	        content: '';
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100%;
	        height: 100%;
	        background-color: rgba(0, 0, 0, 0.39);
        }
    }
`

export const texts = css`
    position: absolute;
    bottom: 33px;
	left: 24px;
	width: 90%;
    max-height: 204px;
`

export const category = css`
    color: ${palette.common.white};
    font-size: 1.1rem;
    border: 1px solid currentColor;
    padding: 4px 6px;
`

export const title = css`
    margin-top: 6px;
	color: ${palette.common.white};
    font-size: 1.6rem;
    line-height: 25.6px;
`

export const date = css`
    margin-top: 3px;
	color: ${palette.common.white};
	font-size: 1.2rem;
    font-weight: 500;
    line-height: 20px;
`