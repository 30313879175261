import { useStaticQuery, graphql } from 'gatsby'

const MAX_LENGTH = 100

export const useOnsenTimesContents = (length?: number) => {
    const limit = length ?? MAX_LENGTH

    const { contents } = useStaticQuery<GatsbyTypes.useOnsenTimesContentsQuery>(graphql`
      query useOnsenTimesContents {
          contents: allMicrocmsNews(sort: {fields: postDate, order: DESC}) {
              edges {
                  node {
                      title
                      contents
                      newsId
                      category
                      thumbnail {
                          url
                      }
                      publishedAt(formatString: "YYYY.MM.DD")
                      postDate(formatString: "YYYY.MM.DD")
                  }
              }
          }
      }
  `)

  return contents.edges.slice(0, limit)
}