import { useStaticQuery, graphql } from 'gatsby'

const MAX_LENGTH = 100

export const useOnsenTimesKeywords = (length?: number) => {
    const limit = length ?? MAX_LENGTH

    const { keywords } = useStaticQuery<GatsbyTypes.useOnsenTimesKeywordsQuery>(graphql`
        query useOnsenTimesKeywords {
            keywords: allMicrocmsKeywords {
                edges {
                    node {
                        title
                        keywordsId
                    }
                }
            }
        }
    `)

    return keywords.edges.slice(0, limit)
}